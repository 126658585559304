import LineGradient from "../components/LineGradient";
import { motion } from "framer-motion";
import profileImage from "../assets/person-1.jpg";
import profileImage2 from "../assets/person-2.jpg";
import profileImage3 from "../assets/person-3.jpg";

const Testimonials = () => {
  const testimonialStyles = `mx-auto relative max-w-[400px] h-[350px] flex flex-col
  justify-end p-16 mt-48 before:absolute before:top-[-120px] before:-ml-[110px] before:left-1/2`;

  return (
    <section id="testimonials" className="pt-32 pb-16">
      {/* HEADING */}
      <motion.div
        className="md:w-1/3 text-center md:text-left"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ delay: 0.2, duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, x: -50 },
          visible: { opacity: 1, x: 0 },
        }}
      >
        <p className="font-playFair font-semibold text-4xl mb-5 text-red">
          MY <span className="text-red">TESTIMONIALS</span>
        </p>
        <LineGradient width="mx-auto w-2/4" />
        <p className="mt-10 mb-7 text-gray-300 ">
          Clients/Colleagues' honest review
        </p>
      </motion.div>
      {/* TESTIMONIALS */}
      <div className="bg-red md:flex md:justify-between gap-8">
        <motion.div
          className={`bg-green-600 rounded-xl  ${testimonialStyles}`}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, scale: 0.8 },
            visible: { opacity: 1, scale: 1 },
          }}
        >
          <img
            className="absolute shadow-xl shadow-red rounded-xl w-[15rem] top-[15%] left-[50%]  transform translate-y-[-50%] translate-x-[-50%] mx-auto"
            src={profileImage}
            alt="profile image"
          />
          <p className="font-playFair text-2xl sm:text-6xl">"</p>
          <p className="text-center text-sm sm:text-lg">
            Adonis is beyond capable at delivering outstanding results. We are
            impressed with her skill in web design and development.
          </p>
        </motion.div>
        <motion.div
          className={`bg-blue rounded-xl ${testimonialStyles} before:content-person1 before:w-5`}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, scale: 0.8 },
            visible: { opacity: 1, scale: 1 },
          }}
        >
          <img
            className="absolute shadow-xl shadow-yellow rounded-xl w-[15rem] top-[15%] left-[50%]  transform translate-y-[-50%] translate-x-[-50%] mx-auto"
            src={profileImage2}
            alt="profile image"
          />
          <p className="font-playFair text-2xl sm:text-6xl">"</p>
          <p className="text-center text-sm sm:text-lg">
            The website is built exactly as I wanted, the layout is perfect and
            you modify the changes I needed just as I expected. Thank you very
            much!
          </p>
        </motion.div>
        <motion.div
          className={`bg-yellow rounded-xl ${testimonialStyles} relative before:content-person1`}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          transition={{ delay: 0.4, duration: 0.5 }}
          variants={{
            hidden: { opacity: 0, scale: 0.8 },
            visible: { opacity: 1, scale: 1 },
          }}
        >
          <img
            className="absolute shadow-xl shadow-blue rounded-xl w-[15rem] top-[15%] left-[50%]  transform translate-y-[-50%] translate-x-[-50%] mx-auto"
            src={profileImage3}
            alt="profile image"
          />
          <p className="font-playFair text-2xl sm:text-6xl">"</p>
          <p className="text-center text-sm sm:text-lg">
            Great communication and very fast at getting the job done. Pleasure
            to work with. Will continue to work with on many projects as I have
            done in the past. Great service!
          </p>
        </motion.div>
      </div>
    </section>
  );
};

export default Testimonials;
