import SocialMediaIcons from "../components/SocialMediaIcons";
import { useState, useEffect } from "react";

const Footer = () => {
  const [date, setDate] = useState(2021);

  useEffect(() => {
    const year = new Date().getFullYear();
    setDate(year);
  }, [0]);

  return (
    <footer className="h-64 bg-red pt-10">
      <div className="w-5/6 mx-auto">
        <SocialMediaIcons />
        <div className="md:flex justify-center md:justify-between text-center">
          <p className="font-playFair font-semibold text-2xl text-yellow">
            Adonis Joe
          </p>
          <p className="font-playFair text-md text-yellow">
            ©{`${date}`} Adons. All Rights Reserved
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
