import React from "react";
import twitter from "../assets/twitter.svg";
import facebook from "../assets/facebook.svg";
import youtube from "../assets/youtube.svg";
import linkedin from "../assets/linkedin.png";
import github from "../assets/github.png";

const SocialMediaIcons = () => {
  return (
    <div className="flex justify-center items-center md:justify-start my-10 gap-10">
      <a
        className="hover:opacity-50 transition duration-500"
        href="https://www.linkedin.com/in/emeka-joe-74698583/"
        target="_blank"
        rel="noreferrer"
      >
        <img className="w-9" src={linkedin} alt="linkedin" />
      </a>
      <a
        className="hover:opacity-50 transition duration-500"
        href="https://twitter.com/adonisejoe"
        target="_blank"
        rel="noreferrer"
      >
        <img className="w-9" src={twitter} alt="twitter" />
      </a>
      <a
        className="hover:opacity-50 transition duration-500"
        href="https://www.youtube.com/channel/UCcbg74wtBnI3F2KonH_9mng"
        target="_blank"
        rel="noreferrer"
      >
        <img className="w-9" src={youtube} alt="youtube" />
      </a>
      <a
        className="hover:opacity-50 transition duration-500"
        href="https://www.facebook.com/adonisejoe"
        target="_blank"
        rel="noreferrer"
      >
        <img className="w-9" src={facebook} alt="facebook" />
      </a>
      <a
        className="hover:opacity-50 transition duration-500 "
        href="https://www.facebook.com/adonisejoe"
        target="_blank"
        rel="noreferrer"
      >
        <img
          className="w-9 shadow-lg shadow-gray-500"
          src={github}
          alt="facebook"
        />
      </a>
    </div>
  );
};

export default SocialMediaIcons;
