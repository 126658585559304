import LineGradient from "../components/LineGradient";
import { motion } from "framer-motion";
import { useState, useEffect } from "react";
import project1 from "../assets/project1.png";
import project2 from "../assets/project2.png";
import project3 from "../assets/project3.png";
import project4 from "../assets/project4.png";
import project5 from "../assets/project5.png";
import project6 from "../assets/project6.png";
import project7 from "../assets/project7.png";

const container = {
  hidden: {},
  visible: {
    transition: { staggerChildren: 0.2 },
  },
};

const projectVariant = {
  hidden: { opacity: 5, scale: 0.8 },
  visible: { opacity: 1, scale: 1 },
};

const Project = ({ title, src, place }) => {
  const overlayStyles = `absolute h-full w-full opacity-0 hover:opacity-90 transition duration-500 bg-grey z-30
  flex flex-col justify-center items-center text-center p-16 text-deep-blue`;
  const projectTitle = title.split(" ").join("-").toLowerCase();

  return (
    <motion.div
      variants={projectVariant}
      className="relative h-[30rem] overflow-hidden bg-red"
    >
      <div className={overlayStyles}>
        <p className="text-2xl font-playFair">{title}</p>
        <p className="mt-7">{place}</p>
      </div>
      <img className="w-[25rem]" src={src} alt={projectTitle} />
    </motion.div>
  );
};
const Projects = () => {
  const [random, setRandom] = useState(0);

  useEffect(() => {
    function randomColors() {
      const random = Math.floor(Math.random() * 5 + 1);
      console.log(random % 2 === 0);
      console.log(random);
      return random;
    }

    setInterval(() => {
      setRandom(randomColors);
    }, 5000);
  }, []);

  return (
    <section id="projects" className="pt-48 pb-48">
      {/* HEADINGS */}
      <motion.div
        className="md:w-2/4 mx-auto text-center"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, y: -50 },
          visible: { opacity: 1, y: 0 },
        }}
      >
        <div>
          <p className="font-playFair font-semibold text-4xl">
            <span className="text-red">PRO</span>JECTS
          </p>
          <div className="flex justify-center mt-5">
            <LineGradient width="w-1/3" />
          </div>
        </div>

        <p className="mt-10 mb-7">
          Some of the world best projects I have built are depicted below.
        </p>
      </motion.div>
      {/* PROJECTS */}
      <div className="flex justify-center">
        <motion.div
          className="sm:grid sm:grid-cols-3"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.5 }}
          variants={container}
        >
          {/* ROLE 1 */}
          <div
            className={`flex justify-center text-center
           items-center p-10 transition-all duration-[1000ms] ${
             random % 2 === 0 ? "bg-red" : "bg-blue"
           } max-w-[400px] max-h-[500px] text-2xl font-playFair font-semibold`}
          >
            BEAUTIFUL USER INTERFACE
          </div>

          <Project
            title="project 1"
            src={project1}
            place="Project 1 made with ❤️"
          />
          <Project
            title="project 2"
            src={project2}
            place="Project 2 made with ❤️"
          />
          <Project
            title="project 3"
            src={project3}
            place="Project 3 made with ❤️"
          />
          <Project
            title="project 4"
            src={project4}
            place="Project 4 made with ❤️"
          />
          <Project
            title="project 5"
            src={project5}
            place="Project 5 made with ❤️"
          />
          {/* ROW 2 */}
          <Project
            title="project 6"
            src={project6}
            place="Project 6 made with ❤️"
          />

          {/* ROW 3 */}
          <Project
            title="project 7"
            src={project7}
            place="Project 7 made with ❤️"
          />
          <div
            className={`flex justify-center text-center
           items-center p-10 transition-all duration-[2000ms] ${
             random % 2 !== 0 ? "bg-red" : "bg-blue"
           } max-w-[400px] max-h-[500px] text-2xl font-playFair font-semibold`}
          >
            SMOOTH USER EXPERIENCE
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default Projects;
